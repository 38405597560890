<template>
    <section class="py-4 bg-black">
        <div class="container pos-r">
            <div class="text-center sm-fl-y-lg-fl-x fl-j-c">
                <p class="w-100 text-center fl-gr-1 mb-4 mb-lg-0">
                    Copyright &copy; <span>{{ new Date().getFullYear() }} </span>Contractace</p>
                <div class="ml-lg-auto d-inline social-media">
                    <a href="https://www.facebook.com/ContractAce" class="social-icon"><i class="fa fa-facebook"></i></a>
                    <a href="https://www.instagram.com/invites/contact/?i=1fbdsavsyz9j5&utm_content=ntgkunh" class="social-icon"><i class="fa fa-instagram"></i></a>
<!--                    <a href="support@contractace.com" class="social-icon"><i class="fa fa-envelope"></i></a>-->
                    <a href ="mailto: support@contractace.com" class="social-icon"><i class="fa fa-envelope"></i></a>
                    <a href="https://www.linkedin.com/company/contractace/" class="social-icon"><i class="fa fa-linkedin"></i></a>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: 'FooterSection'
};
</script>

<style lang="scss" scoped>

.social-media {

    @media (min-width: 992px) {
        position: absolute;
        right: 0;
    }

}

.bg-black {
    background-color: black;
}

.social-icon {
    padding: 1rem;
    transition: all 300ms ease-in-out;
    border: 2px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-left: 10px;
    text-decoration: none;

    &:hover {
        //opacity: 0.8;
        background-color: var(--color-secondary);
        color: black;
    }
}

.ml-lg-auto {
    @media (min-width: 992px) {
        margin-left: auto !important;
    }
}
</style>
