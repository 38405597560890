<template>
    <div class="bg-2" id="top">
        <notifications/>
        <div class="nav-container">
            <lego-nav-bar class="nav-he-basic-3 container" drop-down-animation-mobile="fade" shadow="0"
                          drop-down-animation="zoom" :items="menu">
                <template #logo>
                    <img src="../assets/img/logo/logo.png" @click="redirect" alt="">
                </template>
            </lego-nav-bar>
        </div>
        <div class="page-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <!--        <div class="bg-dark p-3">-->
        <!--            Powered by Xeoscript Technologies-->
        <!--        </div>-->
        <footer-section></footer-section>
    </div>

</template>

<script>
import LegoNavBar from '../../src/components/NavBar';
import getMenu from '../data/menu';
import FooterSection from '@/views/components/FooterSection';

export default {
    components: {
        FooterSection,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        title: 'eProcurement | Construction Contract | eTender',
        titleTemplate: 'ContractAce | %s',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'ContractAce is an open and customisable eProcurement platform specifically designed for Construction & Engineering companies.'
            },
            {
                name: 'keywords',
                content: 'contractace, contract management services in India, contract management, e-tendering in India, construction software, procurement software, e-tenders, eprocurement, e-procurementprocurement ,procurement service in India'
            }
        ]
    },
    methods: {
        redirect () {
            this.$router.push({ path: '/' });
        }
    },
    name: 'MainLayout'
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

//
//.page-content {
//    margin-top: 57px;
//
//    @media (min-width: 992px) {
//        margin-top: 87px;
//    }
//
//}
</style>
