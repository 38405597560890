import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            class: 'ml-auto',
            text: 'Home',
            link: '/'
        },
        {
            text: 'Services',
            link: '/service/'

        },
        {
            text: 'About Us',
            link: '/about/'
        },
        {
            text: 'Packages',
            link: '/packages/'
        },
        {
            text: 'Resources',
            items: [
                {
                    text: 'Training Video',
                    link: '/training-videos/'
                },
                {
                    text: 'Knowledge Hub',
                    link: '/knowledge-hub/'
                }
            ]
        },
        {
            text: 'Contact Us',
            link: '/contact/'
        },
        {
            class: 'custom-btn-contain ml-lg-3',
            text: 'Login',
            link: 'https://admin.contractace.com/auth/login/',
            type: 'button',
            btnAttrs: {
                design: 'animated-b',
                color: 'primary'
            }
        }
    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
